import React, { useEffect, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import { useAuth } from "@auth/useAuth"
import { Link, navigate } from "gatsby"
import get from "lodash/get"
import getOptimizedImageUrl from "../../utils/getOptimizedImageUrl"
import * as styles from "./index.module.less"

const LocationsPage = () => {
  const {
    makeRequest,
    isAuthenticated,
    hasScope,
    user,
    isWorksimplyAdmin,
    isApiUser,
  } = useAuth() || {}

  const [locations, setLocations] = useState([])
  const [progress, setProgress] = useState(true)

  const [pageReady, setPageReady] = useState(false)

  useEffect(() => {
    if (!isAuthenticated || !get(user, "id", false)) return
    if (isWorksimplyAdmin && isApiUser) {
      navigate(`/`)
      return
    }

    if (locations.length > 0) {
      setPageReady(true)
      return
    }

    if (hasScope("read:locations")) {
      setPageReady(true)
    }

    makeRequest("fetch-categorized-locations").then((res) => {
      setLocations(get(res, "spaces[0].spaces") || [])
      setProgress(false)
      setPageReady(true)
    })
  }, [isAuthenticated, user])

  if (!pageReady)
    return (
      <>
        <SEO title="Locations" />
        <Layout title={"Locations"} progress={progress} />
      </>
    )
  return (
    <>
      <SEO title="Locations" />
      <Layout title={"Locations"} progress={progress}>
        {locations.length === 0 ? (
          <NoLocations />
        ) : (
          <Locations locations={locations} />
        )}
      </Layout>
    </>
  )
}

const NoLocations = () => {
  return <p>You don't have any locations yet.</p>
}

const Locations = ({ locations = [] }) => {
  return (
    <div className="row">
      {locations.map((location) => {
        return (
          <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={location.id}>
            <div className={styles.location_block_image}>
              {location.hero_image_url && (
                <img
                  src={getOptimizedImageUrl(location.hero_image_url, 500)}
                  alt=""
                />
              )}
            </div>
            <h5>{location.space_name}</h5>
            <p className={"mb-1"}>
              {location.office_address && location.office_address.short_address
                ? location.office_address.short_address
                : "N/A"}
            </p>
            <Link
              to={`/location/${location.id}`}
              className={styles.location_block_link}
            >
              Edit location
            </Link>
          </div>
        )
      })}
    </div>
  )
}
export default LocationsPage
