const BASE_URL = "https://d331na0x6n3vy8.cloudfront.net/"
const URL_CONSTANT = "https://images.worksimply.ca/"

const optimize = (url, width) => {
  if (!url || !width) {
    return
  }

  const key = url.replace(URL_CONSTANT, "").trim()

  const payload = {
    bucket: "images.worksimply.ca",
    key,
    edits: {
      resize: {
        fit: "cover",
        width,
      },
    },
  }

  return BASE_URL + btoa(JSON.stringify(payload))
}

export default optimize
